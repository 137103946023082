<template>
  <div class="profile-username">{{ firstName }}, {{ lastName }}</div>
</template>

<script>
import authentication from "../../authentication";

export default {
  computed: {
    firstName() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserFirstname();
    },
    lastName() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserLastname();
    },
  },
};
</script>
